

.campaign-list {
  margin-top: 150px;
}

.content-banner
    width: 100%;
    display: flex
    justify-content: center
    align-items: center

.content-promotion
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.banner
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.dark
    background: #000
    width: 100%
    padding-top:2rem

.white
    background: white
    width: 100%
    padding-top:2rem

.text-black
    color: $color-bnn-gray-medium

.text-white
    color: white

.end-line
    background $color-bnn-gray-light-2
    width 100%
    height 1.25rem
    margin-top: 4rem
